<template>
  <app-layout>
    <div class="flex flex-col items-center p-4">
      <div class="w-full xl:px-36 sm:px-8">
        <div class="flex flex-row items-center py-4">
          <h1 class="text-lg font-bold inline-flex items-center">
            <sa-icon-button iconClass="bx bx-left-arrow-alt" @click="handleCreateProductClick" with-bg />
            <strong>&nbsp;Agregar Producto</strong>
          </h1>
          <div class="ml-auto">
            <sa-button type="primary" class="items-center px-3 py-3 font-bold rounded" @click="createProduct">
              Guardar
            </sa-button>
          </div>
        </div>
        <div class="flex flex-col space-y-6">
          <product-form :loading=loading :form=form>
          </product-form>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import _ from 'lodash';
import ProductForm from './Form';
import error from '@/mixins/error';
import AppLayout from '@/components/layouts/AppLayout.vue';
import ProductService from '@/services/ProductService';

export default {
  name: 'ProductCreate',
  components: {
    AppLayout,
    ProductForm,
  },
  mixins: [
    error,
  ],
  data() {
    return {
      loading: false,
      errorMessage: undefined,
      form: {
        name: undefined,
        description: undefined,
        category: undefined,
        brand_model: undefined,
        cost: 0,
        price: 0,
        quantity: 0,
        minStockAlert: null,
        maxStockAlert: null,
        color: undefined,
        details: undefined,
        location: undefined,
        supplier: undefined,
        lastPurchasedAt: null,
      }
    };
  },
  'errorMessage': {
    deep: true,
    handler: _.debounce(async function () {
      if (this.errorMessage) {
        await this.$toastr.e(this.errorMessage);
        this.errorMessage = undefined;
      }
    }, 300)
  },
  methods: {
    invalidForm() {
      const invalidName = _.isEmpty(this.form.name) || _.isNil(this.form.name);
      if (invalidName) {
        this.$toastr.e('El nombre no es valido');
      }

      const invalidCategory = _.isNil(this.form.category);
      if (invalidCategory) {
        this.$toastr.e('La categoria no es valida');
      }

      return invalidName || invalidCategory;
    },
    async createProduct() {
      if (this.invalidForm()) {
        return;
      }

      this.loading = true;
      try {
        const request = {
          name: this.form.name,
          description: this.form.description,
          category: this.form.category,
          // brandModel: this.form.brand_model,
          cost: parseFloat(this.form.cost),
          price: parseFloat(this.form.price),
          quantity: parseFloat(this.form.quantity),
          businessInfoId: this.$store.state.business.id,
          minStockAlert: this.form.minStockAlert,
          maxStockAlert: this.form.maxStockAlert,
          color: this.form.color,
          details: this.form.details,
          location: this.form.location,
          supplierId: this.form.supplier,
        };
        const product = await ProductService.create({ product: request });
        if (product) {
          await this.$router.push({ name: 'products.index' });
          await this.$toastr.s('Se creo con exito');
        }
      } catch (error) {
        if (error.statusError === 409) {
          this.askToRedirectEditing(error.message);
          return;
        }
        const message = this.getErrorMessage(error);
        this.$toastr.e(message);
      } finally {
        this.loading = false;
      }
    },
    handleCreateProductClick() {
      if (this.$store.state.business.id) {
        this.$router.push({ name: 'products.index' });
      } else {
        this.$toastr.i("Tienes que estar en una sucursal para poder crear productos");
      }
    },
    askToRedirectEditing(id) {
      this.$confirm(
        `El producto <b>${this.form.name}</b> ya se encuentra registrado, quieres ir a editarlo?`, {
        type: 'info',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.$router.push({ name: 'products.read', params: { id } });
      }
      );
    },
  },
}
</script>
